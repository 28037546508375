import React, {useEffect, useState} from "react";
import {AddToastFunction} from "../../../../utils/types/toast";
import {ArtifactsApiServiceFactory} from "../../../../services/sam/factory";
import {Button, Card, Icon} from "@salesforce/design-system-react";
import ChangeCaseInput from "../../../../utils/components/input/changeCaseInput";
import CustomSpinnerOverlay from "../../../../utils/components/spinner/custom_spinner_overlay";
import QuickLinkList, {
    AdminSAMDeploymentGuide,
    PCSKAvailability, PCSKTroubleshootingGuide,
    QuickLinkItem
} from "../../../../utils/components/quick_links/QuickLinks";
import ArtifactSelector from "../../../../utils/components/input/artifactSelector";
import {ChangeCaseMetadata} from "../../../../utils/types/gus";
import ImplementationStepSelector from "../../../../utils/components/input/changeCaseImplementationSteps";
import ThemedBadge from "../../../../utils/components/tag/tag";
import {GenericDeploymentSubmissionResponse} from "../../../../utils/types/deployments/genericDeploymentResponse";

type StackSetDeploymentsProps = {
    addToast: AddToastFunction;
    navigationBar: React.ReactNode;
};

const PCSKAdminSAMDeploymentsPage: React.FC<StackSetDeploymentsProps> = ({
                                                                                  navigationBar,
                                                                                  addToast,
                                                                              }) => {

    const [changeCaseID, setChangeCaseID] = useState<string>('');
    const [isValidChangeCase, setIsValidChangeCase] = useState<boolean>(false);

    const [selectedArtifact, setSelectedArtifact] = useState<string>('');
    const [isDeployInProgress, setIsDeployInProgress] = useState<boolean>(false);
    const [disableDeployButton, setDisableDeployButton] = useState<boolean>(false);
    const [ccMetadata, setCCMetadata] = useState<ChangeCaseMetadata | null>(null);
    const [selectedImplStepID, setSelectedImplStepID] = useState<string>('');
    const [triggerComplianceCheck, setTriggerComplianceCheck] = useState<boolean>(false);
    const [lowerEnvDeploymentComplete, setLowerEnvDeploymentComplete] = useState<boolean>(true);
    const [deployAPIResponse, setDeployAPIResponse] = useState<GenericDeploymentSubmissionResponse | null>(null);

    const artifactApiService = ArtifactsApiServiceFactory.getService();

    useEffect(() => {
        setDisableDeployButton(isDeployInProgress || !isValidChangeCase || selectedArtifact == "" || !lowerEnvDeploymentComplete || !isValidImplementationStep())
    }, [isValidChangeCase, selectedArtifact, isDeployInProgress, lowerEnvDeploymentComplete, selectedImplStepID]);
    useEffect(() => {
        setTriggerComplianceCheck( isValidChangeCase && isValidImplementationStep())
    }, [isValidChangeCase, selectedImplStepID]);
    const disableImplStep = () => {
        return isDeployInProgress || !isValidChangeCase
    }
    const resetImplStep = () => {
        return !isValidChangeCase
    }
    const isValidImplementationStep = () => {
        return selectedImplStepID.length > 0
    }
    const disableComplianceChecks = () => {
        return isDeployInProgress || !isValidChangeCase || !isValidImplementationStep()
    }

    const resetComplianceChecks = () => {
        return !isValidChangeCase ||  !isValidImplementationStep()
    }

    const resetArtifact = () => {
        return !isValidChangeCase || !isValidImplementationStep()
    }

    const disableArtifact = () => {
        return isDeployInProgress || !isValidChangeCase || !isValidImplementationStep()
    }

    const handleDeployClick = async () => {
        try {
            setIsDeployInProgress(true)
            setDeployAPIResponse(null)
            const response = await artifactApiService.deployArtifact(changeCaseID, selectedImplStepID, selectedArtifact);
            if (response.success) {
                addToast("Deploy Request Submitted Successfully!", `Artifact: ${selectedArtifact} has been submitted for deployment.`, "success");
                setChangeCaseID("")
                setSelectedImplStepID("")
                setSelectedArtifact("")
            }
            setDeployAPIResponse(response)
        } catch (error) {
            console.log("Error deploying artifact", error);
            addToast("Deploy Request Submitted Failed!", `${error}`, "error");
        } finally {
            setIsDeployInProgress(false)
        }
    }

    // Useful links Data
    const usefulPCSKLinks: QuickLinkItem[] = [
        AdminSAMDeploymentGuide,
        PCSKTroubleshootingGuide,
        PCSKAvailability,
    ];

    const getBottomContent = () => {
        if (deployAPIResponse && !deployAPIResponse.success) {
            return <>
                <div className={"slds-size_3-of-4 slds-m-vertical--medium slds-m-horizontal--small"}>
                    <Card hasNoHeader={true}>
                        {
                            <div className="slds-p-horizontal--medium slds-m-vertical--small">
                                <div className="slds-text-wrap slds-size_1-of-1 slds-m-vertical--medium">
                                    <ThemedBadge
                                        className="slds-m-right_medium"
                                        theme={"slds-theme--error"}
                                        text={"Deployment Request Could Not Be Processed!"}
                                    />
                                </div>
                                {deployAPIResponse.errors.length > 0 &&
                                    <ul className="slds-list_dotted">
                                        {deployAPIResponse.errors.map((msg, index) => (
                                            <li key={index} className={"slds-text-color_destructive"}>
                                                {msg}
                                            </li>
                                        ))}
                                    </ul>
                                }
                            </div>
                        }
                    </Card>
                </div>
            </>
        }

        return <></>
    }

    return (
        <div>
            {/* Render navigation bar */}
            {navigationBar}

            <QuickLinkList usefulPCSKLinks={usefulPCSKLinks} />

            <Card
                heading={<>
                    <div className="slds-grid slds-grid_align-spread">
                        <h1>PCSK SAM Deployments</h1>
                    </div>
                </>}
                icon={<Icon category="standard" name="code_playground" size="small"/>}
                className={"slds-m-horizontal--small slds-p-vertical--xx-small slds-size_3-of-4"}
                bodyClassName={"slds-p-horizontal--medium slds-p-bottom--large slds-p-top--medium slds-box slds-m-horizontal--medium"}
            >
                <div className="slds-grid slds-gutters slds-m-around_small">
                    <div className="slds-col slds-size_2-of-12 slds-text-align_left">
                        <label className="slds-form-element__label"><strong>Change Case:</strong></label>
                    </div>
                    <div className="slds-col">
                        <ChangeCaseInput
                            isValidChangeCase={isValidChangeCase}
                            disableInput={isDeployInProgress}
                            changeCaseID={changeCaseID}
                            setChangeCaseID={setChangeCaseID}
                            setIsValidChangeCase={setIsValidChangeCase}
                            deploymentType={"SAM"}
                            mustContainAtLeastOneCommit={true}
                            setCCMetadata={setCCMetadata}
                        ></ChangeCaseInput>
                    </div>
                </div>

                <div className="slds-grid slds-gutters slds-m-around_small">
                    <div className="slds-col slds-size_2-of-12 slds-text-align_left">
                        <label className="slds-form-element__label"><strong>Implementation Step:</strong></label>
                    </div>
                    <div className="slds-size_10-of-12">
                        <div className="slds-grid slds-grid_vertical-align-center">
                            <div className="slds-col">
                                <ImplementationStepSelector
                                    changeCaseMetadata={isValidChangeCase ? ccMetadata : null}
                                    setSelectedStep={setSelectedImplStepID}
                                    disable={disableImplStep()}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slds-grid slds-gutters slds-m-around_small">
                    <div className="slds-col slds-size_2-of-12 slds-text-align_left">
                        <label className="slds-form-element__label"><strong>Artifact:</strong></label>
                    </div>
                    <div className="slds-size_10-of-12">
                        <div className="slds-grid slds-grid_vertical-align-center">
                            <div className="slds-col">
                                <ArtifactSelector
                                    setArtifact={setSelectedArtifact}
                                    disable={disableArtifact()}
                                    prefix={""}
                                    removeSelection={resetArtifact()}
                                    filterContaining={ccMetadata ? ccMetadata.metadata.commit_ids : []}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className={"slds-m-horizontal--medium slds-m-vertical--large"}>*/}
                {/*    <ComplianceChecks*/}
                {/*        triggerComplianceCheck={triggerComplianceCheck}*/}
                {/*        disable={disableComplianceChecks()}*/}
                {/*        reset={resetComplianceChecks()}*/}
                {/*        commitID={extractCommitIDFromArtifact(selectedArtifact)}*/}
                {/*        deploymentType={"StackSetsTemplate"}*/}
                {/*        artifactName={""}*/}
                {/*        setLowerEnvDeploymentComplete={setLowerEnvDeploymentComplete}*/}
                {/*    />*/}
                {/*</div>*/}

                <div className="slds-grid slds-grid_align-end slds-m-horizontal_large slds-m-top_xx-large">
                    <div className="slds-grid slds-grid_vertical-align-center">
                        {isDeployInProgress && <CustomSpinnerOverlay size="small"/>}
                        <Button
                            label={isDeployInProgress ? "Deploying..." : "Submit for Deployment"}
                            variant="brand"
                            disabled={disableDeployButton}
                            onClick={handleDeployClick}
                            className="slds-m-left_large"
                        />
                    </div>
                </div>
            </Card>
            {getBottomContent()}
        </div>
    );
};

export default PCSKAdminSAMDeploymentsPage;
