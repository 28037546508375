export class ChangeCaseMetadata {
    admin_actions_data: {
        allowed: boolean;
        errors: string[];
    };
    metadata: {
        id: string;
        subject: string;
        status: string;
        owner_email: string;
        description: string;
        is_closed: boolean;
        category: string;
        allowed_deployment_types: string[];
        implementation_steps: ChangeCaseImplementationStep[];
        commit_ids: string[];
        scp_operation_ids: string[];
        shadow_implementors: string[];
    };

    // Constructor to initialize default values
    constructor() {
        this.admin_actions_data = { allowed: false, errors: [] };
        this.metadata = {
            id: "",
            subject: "",
            status: "",
            owner_email: "",
            description: "",
            is_closed: false,
            category: "",
            allowed_deployment_types: [],
            implementation_steps: [],
            commit_ids: [],
            scp_operation_ids: [],
            shadow_implementors: []
        };
    }

    /**
     * Creates a ChangeCaseMetadata instance from a JSON object.
     * @param json The JSON object to parse.
     * @returns An instance of ChangeCaseMetadata.
     */
    static fromJSON(json: any): ChangeCaseMetadata {
        const instance = new ChangeCaseMetadata();

        // Safely populate admin_actions_data
        instance.admin_actions_data = {
            allowed: json?.admin_actions_data?.allowed || false,
            errors: json?.admin_actions_data?.errors || []
        };

        // Safely populate metadata
        instance.metadata = {
            id: json?.metadata?.id || "",
            subject: json?.metadata?.subject || "",
            status: json?.metadata?.status || "",
            owner_email: json?.metadata?.owner_email || "",
            description: json?.metadata?.description || "",
            is_closed: json?.metadata?.is_closed || false,
            category: json?.metadata?.category || "",
            allowed_deployment_types: json?.metadata?.allowed_deployment_types || [],
            implementation_steps: json?.metadata?.implementation_steps?.map((step: any) =>
                ChangeCaseImplementationStep.fromJSON(step)
            ) || [],
            commit_ids: json?.metadata?.commit_ids || [],
            scp_operation_ids: json?.metadata?.scp_operation_ids || [],
            shadow_implementors: json?.metadata?.shadow_implementors || []
        };
        return instance;
    }

    /**
     * Gets the implementation step by its step_id.
     * @param step_id The ID of the implementation step.
     * @returns The matching ChangeCaseImplementationStep, or null if not found.
     */
    getImplementationStep(step_id: string): ChangeCaseImplementationStep | null {
        const step = this.metadata.implementation_steps.find((implStep) => implStep.name == step_id);
        return step || null;
    }

    /**
     * Retrieves all allowed users (owner_email, shadow_implementors, and implementation step owners).
     * @returns An array of unique allowed user emails.
     */
    getAllowedUsers(): string[] {
        const allowedUsersSet = new Set<string>();

        // Add the owner email to the set
        allowedUsersSet.add(this.metadata.owner_email);

        // Add shadow implementors to the set
        this.metadata.shadow_implementors.forEach(email => allowedUsersSet.add(email));

        // Add implementation step owners' emails to the set
        this.metadata.implementation_steps.forEach(step => {
            allowedUsersSet.add(step.owner_email);
        });

        // Return the unique allowed users as an array
        return Array.from(allowedUsersSet);
    }

    getFirstValidImplementationStep(userEmail: string): ChangeCaseImplementationStep | null {
        // Filter for valid implementation steps, checking that the planned start time is valid
        const validSteps = this.metadata.implementation_steps.filter(step => {
            const dateObj = step.getLocalPlannedStartDate();
            return dateObj.isValid && (isBeforeNow(dateObj.dateObj) || isWithinNextMinutes(dateObj.dateObj, 30));
        });


        // Find the first valid step that the user is authorized for
        for (let step of validSteps) {
            if (step.validate(userEmail, this.metadata.owner_email, this.metadata.shadow_implementors)) {
                return step; // Return the first valid step that the user is authorized for
            }
        }

        // If no valid step is found or the user is not authorized, return null
        return null;
    }

}


const isBeforeNow = (date: Date): boolean => {
    return date.getTime() < new Date().getTime();
};

const isWithinNextMinutes = (date: Date, minutes: number): boolean => {
    const now = new Date();
    const targetTime = new Date(now.getTime() + minutes * 60000);
    return date.getTime() <= targetTime.getTime();
};

export class ChangeCaseImplementationStep {
    id: string;
    name: string;
    owner_id: string;
    owner_email: string;
    description: string;
    status: string;
    planned_duration_hours: number;
    planned_start_time: string;
    start_time: string;
    end_time: string;
    allowedStartStatuses: string[] = ['Pending Implementation']

    constructor(
        id: string,
        name: string,
        ownerId: string,
        ownerEmail: string,
        description: string,
        status: string,
        plannedDurationHours: number,
        plannedStartTime: string,
        startTime: string,
        endTime: string
    ) {
        this.id = id;
        this.name = name;
        this.owner_id = ownerId;
        this.owner_email = ownerEmail;
        this.description = description;
        this.status = status;
        this.planned_duration_hours = plannedDurationHours;
        this.planned_start_time = plannedStartTime;
        this.start_time = startTime;
        this.end_time = endTime;
    }

    getLabel(): string {
        return `${this.name} - ${this.description} [${this.getLocalPlannedStartTime()}]`
    }

    getMinimalDetails(): string {
        return `${this.description}`;
    }

    getLocalPlannedStartTime() : string {
        const pTime = this.getLocalPlannedStartDate()
        return pTime.isValid ? pTime.dateObj.toLocaleString() : ""
    }

    getLocalPlannedStartDate() : { isValid: boolean; dateObj: Date } {
        return this.getLocalDate(this.planned_start_time)
    }

    /**
     * Validates the ste[] and collects errors.
     * @returns A list of error messages (if any).
     */
    validate(userEmail: string, ccOwner: string, shadowImplementors: string[]): string[] {
        const dateObj = this.getLocalPlannedStartDate();
        const errors: string[] = [];

        // Check Status
        if (!this.allowedStartStatuses.some(str => str.toLowerCase() === this.status.toLowerCase())) {
            errors.push(`invalid status '${this.status}'. Allowed status: ${this.allowedStartStatuses}`);
        }

        // Check Planned Start Time
        if (dateObj.isValid) {
            // Check if the planned start time is within the next 30 minutes or in the past
            if (!(isBeforeNow(dateObj.dateObj) || isWithinNextMinutes(dateObj.dateObj, 30))) {
                // Add error if the start time is more than 30 minutes later than current time
                errors.push(`planned Start Time begins more than 30 minutes later '${dateObj.dateObj.toLocaleString()}'`);
            }
        }

        // Check ownership of the implementation step
        const isCCOwner = userEmail === ccOwner;
        const isImplStepOwner = userEmail === this.owner_email;
        const isShadowImpl = shadowImplementors.includes(userEmail);

        if (!(isCCOwner || isImplStepOwner || isShadowImpl)) {
            // Get unique emails (ccOwner, implementation step owner, shadow implementors)
            const allowedEmails = [ccOwner, this.owner_email, ...shadowImplementors];
            const uniqueEmails = Array.from(new Set(allowedEmails));

            // Construct error message with unique emails
            errors.push(`unauthorized! allowed users are: '${uniqueEmails.join(', ')}'.`);
        }

        // Return collected errors
        return errors;
    }

    getLocalDate = (date: string): { isValid: boolean; dateObj: Date } => {
        // Attempt to create a Date object
        const dateObj = new Date(date);
        const isValid = !isNaN(dateObj.getTime());
        return {
            isValid,
            dateObj: dateObj,
        };
    };

    /**
     * Creates an ImplementationStep instance from a JSON object.
     * @param json The JSON object to parse.
     * @returns An instance of ImplementationStep.
     */
    static fromJSON(json: Record<string, any>): ChangeCaseImplementationStep {
        return new ChangeCaseImplementationStep(
            json.id || "",
            json.name || "",
            json.owner_id || "",
            json.owner_email || "",
            json.description || "",
            json.status || "",
            json.planned_duration_hours || 0,
            json.planned_start_time || "",
            json.start_time || "",
            json.end_time || ""
        );
    }
}


export const GenerateMockImplSteps = (count: number): ChangeCaseImplementationStep[] => {
    return Array.from({ length: count }, (_, index) => {
        const step = new ChangeCaseImplementationStep(
            `Mock id ${index + 1}`,
            `Mock name ${index + 1}`,
            `Mock owner_id ${index + 1}`,
            `mock.email${index + 1}@example.com`,
            `Mock description ${index + 1}`,
            "not started",
            4,
            `2024-09-24T10:29:00.000+0000`,
            "",
            ""
        );
        return step;
    });
};
