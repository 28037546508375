import React from "react";
import { Icon } from "@salesforce/design-system-react";

// Define the type for each link item
export interface QuickLinkItem {
    link: string;
    text: string;
}

export const AdminDesign: QuickLinkItem = {
    text: "PCSK Admin Design",
    link: "https://salesforce.quip.com/31d4A7oh9N8L"
};

export const AdminStackSetsDeploymentGuide: QuickLinkItem = {
    text: "Admin Stacksets Deployment Guide",
    link: "https://salesforce.quip.com/ntQgAbKVstB9"
};

export const StackSetsRepo: QuickLinkItem = {
    text: "StackSets Repo",
    link: "https://git.soma.salesforce.com/identity-access/pcsk-stacksets"
};

export const AWSMasterRepo: QuickLinkItem = {
    text: "StackSets Repo",
    link: "https://git.soma.salesforce.com/identity-access/aws-master/tree/master"
};

export const StaggerGroups: QuickLinkItem = {
    text: "Stagger Groups",
    link: "https://git.soma.salesforce.com/identity-access/pcsk-stacksets/blob/master/config/stagger_groups.json"
};

export const StackSetsConfig: QuickLinkItem = {
    text: "StackSets Config",
    link: "https://git.soma.salesforce.com/identity-access/pcsk-stacksets/blob/master/config/stackset_config.json"
};

export const PCSKTroubleshootingGuide: QuickLinkItem = {
    text: "PCSK Troubleshooting Guide",
    link: "https://salesforce.quip.com/1c4HA0Gl48Vr"
};

export const PCSKAvailability: QuickLinkItem = {
    text: "PCSK Availability",
    link: "https://tabse.internal.salesforce.com/views/UnifiedServiceHealthREADS/SFHealthTrend?Senior%20Engineering%20Leader=Paul%20Constantinides&Service%2FFeature=pcsk-jit&%3Aembed=y&%3Aoriginal_view=y#1"
};

export const AdminSCPDeploymentGuide: QuickLinkItem = {
    text: "Admin SCP Deployment Guide",
    link: "https://salesforce.quip.com/cve4AxjvoSoU"
};

export const SCPStoreRepo: QuickLinkItem = {
    text: "SCP Store Repo",
    link: "https://git.soma.salesforce.com/identity-access/pcsk-scp-store"
};

export const SCPAPIRepo: QuickLinkItem = {
    text: "SCP API Repo",
    link: "https://git.soma.salesforce.com/identity-access/pcsk-scp-api"
};

export const AdminSAMDeploymentGuide: QuickLinkItem = {
    text: "Admin SAM Deployment Guide",
    link: "https://salesforce.quip.com/MhU2AztXE3xL"
};

// Define the props for the LinkList component
interface LinkListProps {
    usefulPCSKLinks: QuickLinkItem[];
}

const QuickLinkList: React.FC<LinkListProps> = ({ usefulPCSKLinks }) => {
    return (
        <div className="slds-grid slds-wrap slds-gutters slds-p-left--small slds-p-bottom--small slds-p-top--xx-small">
            {usefulPCSKLinks.map((item, index) => (
                <div
                    key={index}
                    className="slds-col slds-size_1-of-fit slds-box slds-box_border slds-theme_default slds-m-horizontal_small"
                >
                    <div className="slds-grid slds-gutters_small slds-align-center slds-text-align_left">
                        <div className="slds-m-right_small">
                            <Icon
                                category="standard"
                                name="link"
                                size="small"
                            />
                        </div>
                        <div className="slds-m-top--xxx-small">
                            <a
                                href={item.link}
                                className="slds-text-link slds-text-color_action slds-m-right--small"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {item.text}
                            </a>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default QuickLinkList;
